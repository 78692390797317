@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{margin: 0; padding: 0; box-sizing: border-box;}
body{
  background-color: #EDEDED;
  font-family: 'Be Vietnam Pro', sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background: rgb(184, 184, 184); 
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(23, 23, 23); 
}